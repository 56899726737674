import { createWebHistory, createRouter } from "vue-router";
import util from "@/util"

const requirePasscode = (to, from, next) => {
    const is_logged_in = localStorage.getItem("team_id")
    if (is_logged_in) {
        next()
    }
    else
        next("/run")
}
const requireAdmin = (to, from, next) => {
    const admin_token = localStorage.getItem("admin_token")
    if (admin_token){
        util.requestServer("POST", '/auth', { token: admin_token },
          () => next(),
          () => { alert("로그인이 필요합니다"); next("/admin") }
        )
    }
    else { alert("로그인이 필요합니다"); next("/admin") }
}
/*
const allowMissionAccessOnlyFromList = (to, from, next) => {
    console.log(from.fullPath)
    if (from.fullPath === "/run/missions")
        next()
    else
        next("/run/missions")
}*/
const checkValidSession = (to, from, next) => {
    const session_id = localStorage.getItem("session_id")
    if (session_id){
        util.requestServer("GET", `/team/session/${session_id}`, {},
          () => next(),
          (err, msg) => {alert(msg); util.clearUserData(); next("/run");}
        )
    }
    else { alert("세션이 만료되었습니다."); util.clearUserData(); next("/run");}
}

const routes = [
    {
        path: "/admin",
        name: "Admin Template",
        component: () => import("@/pages/admin/AdminTemplate"),
        children: [
            {
                path: "",
                name: "Admin Home",
                component: () => import("@/pages/HomePage")
            },
            {
                path: "menu",
                name: "Admin Menu",
                component: () => import("@/pages/admin/AdminMenu"),
                beforeEnter: requireAdmin,
            },
            {
                path: "setting",
                name: "Admin Setting",
                component: () => import("@/pages/admin/AdminSetting"),
                beforeEnter: requireAdmin,
            },
            {
                path: "company",
                name: "Company Management",
                component: () => import("@/pages/admin/CompanyManagement"),
                beforeEnter: requireAdmin,
            },
            {
                path: "company/form",
                name: "Company Form",
                component: () => import("@/pages/admin/CreateCompany"),
                beforeEnter: requireAdmin,
            },
            {
                path: "company/:id",
                name: "Company Dashboard",
                component: () => import("@/pages/admin/CompanyDashboard"),
                beforeEnter: requireAdmin,
            },
        ],
    },
    {
        path: "/staff",
        name: "Staff Template",
        component: () => import("@/pages/staff/StaffTemplate"),
        children: [
            {
                path: "",
                name: "Staff login",
                component: () => import("@/pages/staff/StaffLogin")
            },
            {
                path: "home",
                name: "Staff Home",
                component: () => import("@/pages/staff/StaffManagement"),
            },
            {
                path: "missions/:mission_id",
                name: "Staff Mission Management",
                component: () => import("@/pages/staff/StaffMissionManagement"),
            },
            {
                path: "scoreboard",
                name: "Staff Scoreboard",
                component: () => import("@/pages/staff/StaffScoreboard"),
            },
            {
                path: "gps",
                name: "Staff GPS",
                component: () => import("@/pages/staff/StaffGPS"),
            },
            {
                path: "maps",
                name: "Staff Maps",
                component: () => import("@/pages/staff/StaffMaps"),
            },
        ]
    },
    {
        path: "/run",
        name: "User Template",
        component: () => import("@/pages/user/UserTemplate"),
        children: [
            {
                path: "",
                name: "User Login",
                component: () => import("@/pages/user/UserLogin"),
            },
            {
                path: "home",
                name: "User Home",
                component: () => import("@/pages/user/UserHome"),
                beforeEnter: [requirePasscode, checkValidSession],
            },
            {
                path: "scoreboard",
                name: "User Scoreboard",
                component: () => import("@/pages/user/UserScoreboard"),
                beforeEnter: [requirePasscode, checkValidSession],
            },
            {
                path: "missions",
                name: "User Mission List",
                component: () => import("@/pages/user/UserMissionList"),
                beforeEnter: [requirePasscode, checkValidSession],
            },
            {
                path: "missions/:mission_id",
                name: "User Mission Inspect",
                component: () => import("@/pages/user/UserMissionInspect"),
                beforeEnter: [requirePasscode, checkValidSession/*, allowMissionAccessOnlyFromList*/]
            },
            {
                path: "maps",
                name: "User Maps",
                component: () => import("@/pages/user/UserMaps"),
                beforeEnter: [requirePasscode, checkValidSession],
            },
            {
                path: "gps",
                name: "User GPS",
                component: () => import("@/pages/user/UserGPS"),
                beforeEnter: [requirePasscode, checkValidSession],
            },
        ]
    },
]




const router = createRouter({
    history: createWebHistory(),
    routes,
});
/*
router.beforeEach((to, from) => {
    console.log("to", to)
    console.log("from", from)
})*/

export default router;