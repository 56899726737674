<template>
  <router-view/>
  <div id="global-background"/>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import "./css/theme.css";
* { font-family: 'Spoqa Han Sans Neo', 'sans-serif'; }
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body, html{
  margin: 0;
  width:100vw;
  height: 100%;
}
#global-background{
  position: fixed;
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
  z-index: -999999;
  top: 0;
  left: 0;
}
</style>
