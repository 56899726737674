import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'

import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
library.add(faAngleDoubleUp)
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
library.add(faAngleDoubleDown)
import { faBars } from "@fortawesome/free-solid-svg-icons";
library.add(faBars)
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
library.add(faPenToSquare)
import { faTrash } from "@fortawesome/free-solid-svg-icons";
library.add(faTrash)
import { faKey } from "@fortawesome/free-solid-svg-icons";
library.add(faKey)
import { faPlus } from "@fortawesome/free-solid-svg-icons";
library.add(faPlus)
import { faMinus } from "@fortawesome/free-solid-svg-icons";
library.add(faMinus)
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
library.add( faClipboardList )
import { faPersonRunning } from "@fortawesome/free-solid-svg-icons";
library.add( faPersonRunning )
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
library.add( faMapLocationDot )
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
library.add( faPowerOff )
import { faCircle, faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
library.add(faCircle)
library.add(faCircleCheck)
library.add(faCircleXmark)
import { faDiamond } from "@fortawesome/free-solid-svg-icons";
library.add(faDiamond)
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
library.add(faArrowLeft)
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";
library.add(faStar)
library.add(faFileCircleCheck)
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
library.add(faAngleUp)
library.add(faAngleDown)
import { faXmark, faEllipsis, faCheck } from "@fortawesome/free-solid-svg-icons";
library.add(faXmark)
library.add(faEllipsis)
library.add(faCheck)
import { faArrowDownShortWide } from "@fortawesome/free-solid-svg-icons";
library.add(faArrowDownShortWide)
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
library.add(faLocationDot)
import { faMap } from "@fortawesome/free-solid-svg-icons";
library.add(faMap)
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
library.add(faLocationArrow)
import { faLock } from "@fortawesome/free-solid-svg-icons";
library.add(faLock)
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
library.add(faArrowsRotate)
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
library.add(faPeopleGroup)
import { faHouse } from "@fortawesome/free-solid-svg-icons";
library.add(faHouse)
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
library.add(faPaperPlane)
import { faFlag } from "@fortawesome/free-solid-svg-icons";
library.add(faFlag)
import { faFile } from "@fortawesome/free-solid-svg-icons";
library.add(faFile)
import { faBolt } from "@fortawesome/free-solid-svg-icons";
library.add(faBolt)


export default FontAwesomeIcon