// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBgU0RhERh8NQJBYoP_q-1T0Dskmghe7NA",
  authDomain: "activity-operating-system.firebaseapp.com",
  projectId: "activity-operating-system",
  storageBucket: "activity-operating-system.appspot.com",
  messagingSenderId: "940740783949",
  appId: "1:940740783949:web:5dc0e5dc656b832db69104"
};


const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)

export const uploadFileToStorage = async (file) => {
    console.log(file)
    const fileName = crypto.randomUUID() + file.name
    console.log(fileName)

    const fileRef = ref(storage, fileName)
    await uploadBytes(fileRef, file)
    return `https://storage.googleapis.com/activity-operating-system.appspot.com/${fileName}`
}

export const uploadFilesToStorage = async (files) => {
    const result = []
    for (const file of files){
        result.push(await uploadFileToStorage(file))
    }
    return result
}