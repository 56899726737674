import { createApp } from 'vue';
import App from './App.vue';
import BootstrapVue3 from "bootstrap-vue-3";
import router from './router';
import FontAwesomeIcon from './fontawesome-icons';
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import axios from 'axios'

axios.defaults.baseURL = 'https://api.kaetc.com:8080/api'
//axios.defaults.baseURL = 'http://localhost:8080/api'


const app = createApp(App)
app.config.globalProperties.axios = axios
app.use(BootstrapVue3)
app.use(router)
app.use(VideoPlayer)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
