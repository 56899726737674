import axios from 'axios'
import fileDownload from 'js-file-download';
import {uploadFileToStorage} from "./firebase"

//axios.defaults.baseURL = 'https://aosbackend.cafe24app.com:8001/api'
//axios.defaults.baseURL = 'http://localhost:8080/api'

const requestServer = (method, url, payload, next, err) => {
  method = method.toUpperCase()
  switch (method) {
    case "GET":
      axios.get(url)
          .then((res) => {
            if (res.data.success)
              next(res.data.data)
            else
              err(res.data.err, res.data.message)
          })
      break
    case "POST":
      axios.post(url, payload)
          .then((res) => {
            if (res.data.success)
              next(res.data.data)
            else
              err(res.data.err, res.data.message)
          })
      break
    case "PUT":
        axios.put(url, payload)
            .then((res) => {
                if (res.data.success)
                    next(res.data.data)
                else
                    err(res.data.err, res.data.message)
            })
      break
    case "DELETE":
        axios.delete(url)
            .then((res) => {
                if (res.data.success)
                    next(res.data.data)
                else
                    err(res.data.err, res.data.message)
            })
      break
    case "UPLOAD":
      axios.post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then((res) => {
          if (res.data.success)
            next(res.data.data)
          else
            err(res.data.err, res.data.message)
        })
      break
    case "UPLOAD_MULTI":
      axios.post(url, payload, {
        headers: {
          "Content-Type": "undefined"
        }
      })
        .then((res) => {
          if (res.data.success)
            next(res.data.data)
          else
            err(res.data.err, res.data.message)
        })
      break
    case "DOWNLOAD":
      axios.get(url, {responseType: 'blob',})
        .then((res) => {
          fileDownload(res.data, "Answers.zip")
          next(null)
        })
      break
  }
}

const booleanToTinyint = (data) => {
  const result = {}
  for (const key in data){
    if (data[key] === true)
      result[key] = 1
    else if (data[key] === false)
      result[key] = 0
    else
      result[key] = data[key]
  }
  return result
}

const convertTimeInfo = (start_time, end_time) => {
  const s = start_time ? parseDateTime(start_time) : new Date(0)
  const e = end_time ? parseDateTime(end_time) : null
  return [s, e]
}
const calcTime = (s, e) => {
  const now = new Date()

  if ((now - s) < 0) return { status: 0, time: s-now }
  else if (e == null) return { status: 1, time: -1 }
  else if ((now - e) < 0) return { status: 1, time: e-now }
  else return { status: 2, time: -1 }
}

const parseDateTime = (datetime) => {
  const date_and_time = datetime.split('.')[0].split('T')
  const date = date_and_time[0].split('-').map(x => parseInt(x))
  const time = date_and_time[1].split(':').map(x => parseInt(x))
  return new Date(Date.UTC(date[0], date[1]-1, date[2], time[0], time[1], time[2]))
}
const pad = function (num, size) {
  const s = "000000000" + num;
  return s.substr(s.length-size);
}
const headerClass = () =>{
  const theme_count = 13
  const result = {
    theme0: localStorage.getItem("user_theme")==null || localStorage.getItem("user_theme") === "0",
  }
  for (let i = 1; i < theme_count; i++){
    result[`theme${i}`] = localStorage.getItem("user_theme") === `${i}`
  }
  return result
}

const markerSize = (index) => {
  if (index <= 63)
    return [43, 46]
  else if (index <= 69)
    return [106 * 0.3, 109 * 0.3]
  else if (index <= 79)
    return [92 * 0.4, 135 * 0.3]
  else
    return [64, 64]
}
const markerOffset = (index) => {
  if (index <= 63)
    return [21, 44]
  else if (index <= 69)
    return [17 * 0.3, 96 * 0.3]
  else if (index <= 79)
    return [46 * 0.4, 114 * 0.3]
  else
    return [19, 61]
}

const clearUserData = () => {
  localStorage.removeItem("training_id")
  localStorage.removeItem("team_id")
  localStorage.removeItem("team_name")
  localStorage.removeItem("session_id")
}
const clearStaffData = () => {
  localStorage.removeItem("staff_training_id")
}

const uploadFile = (file, callback) => {
  if (file == null) return
  uploadFileToStorage(file)
  .then((url) => callback(url))
  .catch(() => {
    alert("업로드 중 오류 발생")
  })
  // if (file == null) return
  // const formData = new FormData()
  // formData.append("file", file)
  // requestServer("UPLOAD", '/blob', formData,
  //   (data) => { callback(data.publicUrl) },
  //   () => {
  //     alert("업로드 중 오류 발생")
  //   }
  // )
}


function calcCrow(lat1, lon1, lat2, lon2)
{
  const R = 6371000; // m
  const dLat = toRad(lat2-lat1);
  const dLon = toRad(lon2-lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
}

// Converts numeric degrees to radians
function toRad(Value)
{
  return Value * Math.PI / 180;
}

const countPendingTeams = (mission_id, callback) => {
  requestServer("GET", `/scoreboard/by-mission/${mission_id}}`, {},
    (data) => {
      const count = data.filter(s => s.status === 'pending').length

      callback(count)
    }, (err) => { console.log(err); alert("서버와 연결할 수 없습니다") }
  )
}

export default {
  requestServer,
  booleanToTinyint,
  convertTimeInfo,
  calcTime,
  pad,
  headerClass,
  clearUserData,
  clearStaffData,
  markerSize,
  markerOffset,
  uploadFile,
  calcCrow,
  countPendingTeams,
}